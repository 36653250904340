<template>
    <div class="homePage">
      <Header></Header>
      <router-view></router-view>
      <Footer></Footer>
    </div>
  </template>
  <script>
  import "@/assets/css/main.css";
  // import Header from "../components/headers/headers.vue";
  import Header from "../views/shopDecoration/components/page/headerH5.vue";
  import Footer from "../views/shopDecoration/components/page/footerH5.vue";
  export default {
    name: "homePage",
    components: { Header, Footer },
    setup() {
    },
  };
  </script>
  <style scoped lang="less">
  .homePage {
  
    .container {
      display: flex;
      border-top: 1px solid #f7f8fa;
    }
  
    .rightContainer {
      flex-grow: 1;
      width: calc(100% - 240px);
      background-color: #f7f8fa;
    }
  
    :deep(.auth-dialog) {
      .auth-dialog-main {
        width: 100%;
        display: flex;
        justify-content: center;
  
        .auth-dialog-main-panel {
          width: 320px;
        }
      }
  
      .auth-other-opt {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .down-btn {
          font-size: 12px;
  
          a {
            color: #3e7fff;
          }
        }
      }
  
      .auth-dialog-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .tips {
          width: 70%;
          font-size: 12px;
          color: #c7c7c7;
          text-align: left;
          font-family: PingFangSC-Regular;
        }
      }
    }
  }
  </style>
  