<template>
    <div class="footerH5">
        <div class="colorBox" :class="'wui-column-background-'+theme ">
            <p>主办单位：{{portalInfo.sponsor}}</p>
            <p>联系电话：{{portalInfo.tel}}</p>
            <p>联系邮箱：{{portalInfo.email}}</p>
            <p>联系地址：{{portalInfo.address}}</p>
            <div class="wxCode">
                <img :src="commenUrl+portalInfo.tradeImg" alt="">
            </div>
            <p class="name">官方正品交易通道</p>
        </div>
        <div class="blackBox">
            <!-- <p>冀ICP备2024074488号</p>
            <p>冀公网安备13082202000080号</p> -->
            <p v-for="(item,index) in qualificationsList" :key="index">
                <img v-if="item.imgUrl" :src="commenUrl+item.imgUrl" >
                <a :href="item.url" v-if='item.certificationType=="0"' target="_blank" style="margin-right:20px;">{{item.name}}</a>
                <a @click="openPdf(item.fileUrl)" v-if='item.certificationType=="1"' target="_blank" style="margin-right:20px;">{{item.name}}</a>
            </p>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance,ref,computed} from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
export default {
    name: "footer",
    setup(props) {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const store=useStore();
        const theme=computed(()=>store.state.theme);
        let portalInfo=computed(() => store.state.portalInfo);
        // console.log(portalInfo.value)
        // 获取商务资质
        const qualificationsList = ref([])
        const getQualifications=()=>{
        proxy.$request({
            url:"PROTAL_N_INDUSTRYCERTIFICATION_FINDALL",
            method: "post",
            data:{
                showType:'0'
            },
        }).then((res)=>{
            if(res.data.code=="000"){
                qualificationsList.value=res.data.result;
            }
        }).catch((err)=>{
            console.log(err)
        });
        }
        getQualifications();
        const openPdf = (href)=>{
		    if(!href)return
            const url = router.resolve({
                path: 'Pdfcomp',
            });
            let obj = {
                page: proxy.commenUrl + href,
            };
            let params = btoa(JSON.stringify(obj))
            url.href += '?params='+params
            proxy.tool.openWindow(url.href);
        }
        return{
            theme,
            portalInfo,
            qualificationsList,
            openPdf
        }
    }
}
</script>

<style lang="less" scoped>
.footerH5{
    width: 100%;
    margin: 0 auto;
    .colorBox{
        padding: 5.06vw 3.73vw 3.46vw;
        // padding: 19px 14px 13px 14px;
        background: #CA2C07;
        font-size: 3.46vw;
        color: #fff;
        line-height: 5.87vw;
        text-align: center;
        .wxCode{
            width: 23.47vw;
            height: 23.47vw;
            border-radius: 23.47vw;
            margin:3.73vw auto 2.14vw ;
            background:rgba(255,255,255,0.31);
            img{
                width: 100%;
                height: 100%;
            }
        }
        .name{
            font-weight: 600;
            font-size: 3.73vw;
        }
    }
    .blackBox{
        text-align: center;
        padding: 2.93vw 3.73vw 2.93vw;
        line-height: 5.34vw;
        background: #333;
        img{
            width: 3.2vw;
            height: 3.2vw;
            margin-right:1vw;
            vertical-align: text-bottom;
        }
        a{
            color: #8E8E8E;
            font-size: 3.2vw;
            text-decoration: none;
        }
    }
}
</style>