let operateList = [
  {
    value: "0",
    label: "无操作",
    showPlat:['mall','gateway','gatewayH5'],//来源是B2B商城、产业门户、产业门户h5
    config:['1','2'],//来源是首页配置或是专题配置
    showValue: "",
  },
  {
    value: "1",
    label: "链接地址",
    showPlat:['mall','gateway','gatewayH5'],
    config:['1','2'],
    show: "链接地址",
    showValue: "href",
    href:'',
  },
  {
    value: "2",
    label: "商品",
    showPlat:['mall'],
    config:['1','2'],
    name: "goodsList",
    show: "商品名称",
    showValue: "productName",
    href:"/goodsDetails",
    parameter:[{
      name:'id',
      value:'prodoucId'
    }],
    inner:true,
  },
  {
    value: "3",
    label: "商品分类",
    showPlat:['mall'],
    config:['1','2'],
    name: "goodsClassifyList",
    show: "商品类目",
    showValue: "name",
    href:'/goodsList',
    parameter:[{
      name:'categoryId',
      value:'baseCatgId,categoryId'
    }],
    inner:true,
  },
  {
    value: "4",
    label: "供应商",
    showPlat:['mall'],
    config:['1','2'],
    name: "supplierList",
    show: "供应商名称",
    showValue: "shopName",
    href:'/shopIndex',
    parameter:[{
      name:'id',
      value:'id'
    }],
    inner:true,
  },
  {
    value: "5",
    label: "资讯",
    showPlat:['mall','gateway'],
    config:['1','2'],
    name: "articleList",
    show: "资讯名称",
    showValue: "title",
    href:'/newsDetail',
    parameter:[{
      name:'articleId',
      value:'id'
    }],
    inner:true,
  },
  {
    value: "6",
    label: "资讯分类",
    showPlat:['mall','gateway'],
    config:['1','2'],
    name: "newsClassifyList",
    show: "分类名称",
    showValue: "categName",
    href:'/information',
    parameter:[{
      name:'pid',
      value:'id'
    }],
    inner:true,
  },
  {
    value: "7",
    label: "品种列表",
    showPlat:['mall','gateway'],
    config:['1','2'],
    name: "",
    show: "",
    showValue: "",
    href:'/varieties',
    inner:true,
  },
  {
    value: "8",
    label: "基地列表",
    showPlat:['mall'],
    config:['1','2'],
    name: "",
    show: "",
    showValue: "",
    href:'/preferredBase',
    inner:true,
  },
  {
    value: "9",
    label: "基地内容",
    showPlat:['mall'],
    config:['1','2'],
    name: "baseList",
    show: "分类名称",
    showValue: "baseName",
    href:'/baseDetails',
    parameter:[{
      name:'id',
      value:'id'
    }],
    inner:true,
  },
  {
    value: '10',
    label: '检测服务',
    showPlat:['mall'],
    config:['1','2'],
    name:'',
    show:'',
    showValue:'',
    href:'/testService',
    inner:true
  },
  {
    value: '11',
    label: '专题列表',
    showPlat:['mall','gateway'],
    config:['1','2'],
    name: "specialList",
    show: "专题名称",
    showValue: "name",
    href:'/special',
    parameter:[{
      name:'id',
      value:'id'
    }],
    inner:true
  },
  {
    value: '12',
    label: "店铺商城",
    showPlat:['gateway'],
    config:['1','2'],
    name: "",
    show: "",
    showValue: "",
    href:'/shopmall',
    inner:false
  },
  {
    value: '13',
    label: "物流服务",
    showPlat:['mall'],
    config:['1','2'],
    name: "",
    show: "",
    showValue: "",
    href:'/logisticServ',
    inner:true
  },
  
  {
    value: "14",
    label: "资讯",
    showPlat:['gatewayH5'],
    config:['1','2'],
    name: "articleList",
    show: "资讯名称",
    showValue: "title",
    href:'/h5/newsDetail',
    target:"1",
    parameter:[{
      name:'articleId',
      value:'id'
    }],
    inner:true,
  },
  {
    value: "15",
    label: "资讯分类",
    showPlat:['gatewayH5'],
    config:['1','2'],
    name: "newsClassifyList",
    show: "分类名称",
    showValue: "categName",
    href:'/h5/information',
    parameter:[{
      name:'pid',
      value:'id'
    }],
    inner:true,
  },
  {
    value: '16',
    label: '专题列表',
    showPlat:['gatewayH5'],
    config:['1','2'],
    name: "specialListH5",
    show: "专题名称",
    showValue: "name",
    href:'/h5/special',
    parameter:[{
      name:'id',
      value:'id'
    }],
    inner:true
  },
]
module.exports = operateList