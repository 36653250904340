const routerH5 = [{
    path: 'index',
    name: '首页H5',
    component: () => import('@/views/shopDecoration/views/indexH5.vue'),
  },
  {
    path: "special",
    name: "专题页H5",
    component: () => import("@/views/shopDecoration/views/specialH5.vue"),
  },
  {
    path: 'information',
    name: '资讯分类H5',
    component: () => import('@/views/mobile/views/news/list.vue'),
  },
  {
    path: 'newsDetail',
    name: '资讯详情H5',
    component: () => import('@/views/mobile/views/news/detail.vue'),
  },
]
module.exports = routerH5;