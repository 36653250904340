<template>
	<div class="allHeaderH5">
        <div class="fixedBox">
            <div class="topNav">
                <img class="logo" :src="commenUrl + portalInfo.logo" alt="">
                <div class="titBox">
                    <div class="bigT" :class="'wui-column-color-'+theme">{{ portalInfo.name }}</div>
                    <div class="smallT">{{ portalInfo.subName }}</div>
                </div>
                <div class="icon" @click="drawer = true">
                  <img src="@/assets/images/bannerH5/nav_icon.png" alt="">
                </div>
            </div>
        </div>
		<el-drawer v-model="drawer" size="77%" title=""  :before-close="handleClose" class="customDrawer">
		<!-- :class="'wui-column-background-'+theme" -->
			<div class="drawer__content navList" >
				<div class="headLi" @click="toHref(headerLi,ish)"
					:class="[selectedId == headerLi.id ? `headActive` : '']"
					v-for="(headerLi, headerIndex) in headerList" :key="headerIndex">
					<div class="column-name">{{ headerLi.columnName }}</div>
				</div>
				<div style="clear: both;"></div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
import {getCurrentInstance,reactive,toRefs,ref,onMounted,computed,} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import operate from "@/views/shopDecoration/js/operate.js";
export default {
	name: "h5",
	setup() {
		const { proxy } = getCurrentInstance();
		const store = useStore();
		const router = useRouter();
		const portalInfo = computed(() => store.state.portalInfo);
		const theme = computed(() => store.state.theme);
		const backgroundImage = computed(() => store.state.backgroundImage);
		//获取门户信息
		const getPortalInfo = () => {
		proxy.$request({
			url: "INDUSTRYMANGE_FINDDEFAULT",
			method: "post",
			}).then((res) => {
			if (res.data.code == "000") {
				let result = res.data.result;
				store.commit("setPortalInfo",result);
				store.commit("setBackgroundImage", result.backgroundImage);
			}
			})
			.catch((err) => {
			console.log(err);
			});
		};
		const drawer = ref(false)
			//获取栏目配置
		let headerList = ref([
			{
				columnName: "首页",
				columnProperty: "1",
				columnContent: '{"href":"/h5/index"}',
				target: "1",
				columnBanner:'',
			},
		]);
		const getList = () => {
		proxy.$request({
			url: "INDUSTRYCOLUMNMANAGE_FINDPAGELIST",
			method: "post",
			data: {
				source:1
			},
		}).then((res) => {
			if (res.data.code == "000") {
				let data = res.data.result.filter(i=>{
					return i.isShow=='0'
				})
				headerList.value = headerList.value.concat(data);
				selectedId.value = headerList.value[0].id;
				headerList.value.map((item) => {
				if (item.columnProperty == 0) {
					item.path = "/secondPage";
					item.query = { pid: item.columnContentId, columnid: item.id };
				} else if (item.columnProperty == 1) {
					item.path = "/baseMoudle";
					item.query = { id: item.columnContentId, columnid: item.id };
				}
				});
				console.log(headerList.value);
			}
		}).catch((err) => {
			console.log(err);
			});
		};
		getList();
		let selectedId = ref("");
		const toHref = (e, ish5) => {
			selectedId.value = e.id;
			console.log(e,ish5)
			// return
			toPage({
				data: JSON.parse(e.columnContentId||e.columnContent),
				type: e.columnProperty,
				target: e.target,
				columnBanner:e.columnBanner
			});
			if (ish5) isShow.value = false;
			drawer.value = false;
		};
		// 跳转处理
		const toPage = (e) => {
			store.commit("setColumnBanner", e.columnBanner);
			let href = "";
			let str = "";
			if (e.type == "0") {
				// 无操作
			} else if (e.type == "1") {
				//
				href = e.data.href;
			} else {
				let operateLi = operate.find((l) => e.type == l.value);
				href = operateLi.href;
				e.inner = operateLi.inner;
				e.target = operateLi.target||"1"
				if (operateLi.parameter && operateLi.parameter.length > 0) {
					operateLi.parameter.forEach((element, index) => {
						let arr = element.value.split(",");
						let name = "";
						if (arr.length > 1) {
							arr.forEach((m) => {
								if (!name) {
								name = e.data[m];
								}
							});
						} else {
							name = e.data[element.value];
						}
						if (index == 0) {
							str += "?" + element.name + "=" + name;
						} else {
							str += "&" + element.name + "=" + name;
						}
					});
				}
			}
			openWindow(href, e.target, e.inner, str);
		};
		const openWindow = (href, target, inner, str) => {
			if (!href) return;
			if (target == "1") {
				// 当前页签打开
				router.push(href + str);
			} else {
				if (inner) {
					const url = router.resolve({
						path: href,
					});
					proxy.tool.openWindow(url.href + str);
				} else {
					proxy.tool.openWindow(href);
				}
			}
		};
		onMounted(() => {
			getPortalInfo()
		});
		return {
			portalInfo,
			theme,
			backgroundImage,
			drawer,
			headerList,
			selectedId,
			toHref,
			toPage,
			openWindow,
		};
	},
};
</script>
<style lang="less">
.customDrawer{
	.el-drawer__header{
		padding: 4.8vw;
		margin-bottom: 0;
	}
	.el-drawer__body{
		padding: 0;
		.navList{
			.headLi{
				cursor: pointer;
				font-size: 3.73vw;
				font-weight: 500;
				line-height: 5.34vw;
				padding: 3.2vw 3.73vw 3.2vw 3.73vw;
				box-shadow: 0px 1px 1px 0px rgba(204,204,204,0.5);
			}
			.headActive{
				background: #CA2C07;
				color: #fff;
			}
		}
	}
}
.allHeaderH5{
	width: 100%;
  	height: 15vw;
	position: relative;
    .fixedBox{
        width: 100%;
        position: fixed;
        z-index: 99;
    }
	.topNav{
        width: 100%;
        display: flex;
		padding: 1.86vw 3.73vw 1.86vw 3.73vw;
        align-items: center;
        margin: 0 auto;
        background-color: #fff;
        .logo{
            width: 9.62vw;
      		height: 9.62vw;
            background: #F2F2F2;
            margin-right: 1vw;
        }
        .titBox{
            width: 72vw;
            .bigT{
                font-family: MyBinBold;
                font-weight: bold;
                font-size:4.53vw;
				height: 6.4vw;
                color: #000000;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 2px;
            }
            .smallT{
                font-family: PingFangSC, PingFang SC;
                font-size: 3.2vw;
                color: #999999;
                line-height: 4.54vw;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .icon{
            margin-left: auto;
            width: 5.87vw;
      		height: 4.54vw;
            cursor: pointer;
            img{
              width: 100%;
              height: 100%;
            }
        }
	}
}
</style>